<template>
  <div class="rn_ms_section goods_border">
    <!---->
    <div class="rn_ms_box_3">
      <!---->
      <div class="search_wrap">
        <div class="section">
          <h4>램 가게</h4>
          <div class="search_box" v-if="categoryList.length > 0">
            <div
              class="search_list"
              v-for="(cate, index) in categoryList"
              :key="index"
            >
              <h5>{{ cate.title }}</h5>
              <div class="box">
                <div
                  v-for="(provider, idx) in providerList[cate.category_code][
                    'result'
                  ]"
                  :key="idx"
                >
                  <router-link :to="`/mini_shop/${provider.provider_seq}`"
                    ><span class="left">{{
                      provider.provider_name
                    }}</span></router-link
                  >
                </div>
              </div>
              <div
                class="search_more"
                v-if="
                  parseInt(providerList[cate.category_code]['count']) >
                  parseInt(catePages[cate.category_code])
                "
              >
                <router-link
                  to=""
                  @click.native="moreProvider(cate.category_code)"
                  >{{ limit }}개 더보기
                  <img src="images/sub/my_down_ico.png" alt=""
                /></router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="search_box no_data" v-if="categoryList.length <= 0">
          <p>검색 결과가 없습니다.</p>
        </div>
      </div>
      <!---->
    </div>
    <!---->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      searchText: this.$route.query.search || "",
      pages: {},
    };
  },
  created() {
    this.$store.dispatch("common/setFooter", {
      viewFooter: true,
    });
    this.getProviderList(true);
  },
  computed: {
    ...mapState("search", [
      "providerList",
      "categoryList",
      "msg",
      "result",
      "limit",
    ]),
    catePages() {
      Object.keys(this.providerList).map((key) => (this.pages[key] = 1));
      return this.pages;
    },
  },
  methods: {
    async moreProvider(category_code) {
      console.log(this.catePages[category_code]);
      await this.$store.dispatch("search/moreProvider", {
        page: this.catePages[category_code],
        search_text: this.searchText,
        category_code,
      });

      this.catePages[category_code]++;
      this.$forceUpdate();
    },
    getSearch(text, menu) {
      this.searchText = text;
      this.getProviderList(true);
    },
    async getProviderList(reset = false) {
      await this.$store.dispatch("search/getProviderList", {
        search_text: this.searchText,
        reset,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search_box {
  &.no_data {
    text-align: center;
    font-size: 13px;
    line-height: 13px;
    padding: 10px 0;
  }
}
</style>
